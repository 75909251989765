import constants from 'redux/constants';

export const addNotification = (notification) => {
  return {
    type: constants.alert.ADD_NOTIFICATION,
    payload: notification,
  };
};

export const clearNotification = (id) => {
  return {
    type: constants.alert.CLEAR_NOTIFICATION,
    payload: { id },
  };
};
