import axios from 'axios';
import { getStore } from 'components/App';
import { clearSession, setLoggedInUser } from 'redux/actions/authActions';
import { getUser } from 'redux/reducers/authReducer';

import { StatusCodes } from 'utils/constants';

class AxiosWrapper {
  constructor(baseURL) {
    this.client = axios.create({ baseURL });

    this.client.interceptors.request.use(async (request) => {
      const store = getStore();
      const state = store.getState();
      const { token, tokenExpireDate } = getUser(state);

      if (!request.headers) request.headers = {};

      if (!token) return request;

      // refresh token if current time is 1m or less before exp. of acc. token
      const refreshTime = new Date(tokenExpireDate * 1000 - 60000);

      if (refreshTime < new Date()) {
        const refreshTokenUrl = `${process.env.REACT_APP_API}/auth/refresh-token`;

        try {
          const res = await axios.post(refreshTokenUrl, null, { withCredentials: true });

          const sessionData = res.data;
          store.dispatch(setLoggedInUser({ ...res.data }));
          request.headers.Authorization = `Bearer ${sessionData.token}`;
        } catch {
          store.dispatch(clearSession());
        }
      } else {
        request.headers.Authorization = `Bearer ${token}`;
      }

      return request;
    });
  }

  request = async (options) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await this.client(options));
      } catch (error) {
        if (!error.response) {
          reject(error);
          return;
        }

        const { status } = error.response;

        switch (status) {
          case StatusCodes.INVALID_CURRENT_PASSWORD:
            break;
          default:
            reject(error);
        }
      }
    });
  };
}

export default AxiosWrapper;
