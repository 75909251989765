import AxiosWrapper from 'services/AxiosService';

const axiosWrapper = new AxiosWrapper(process.env.REACT_APP_API);

export const login = (data) => {
  return axiosWrapper.request({
    url: `/auth/login`,
    method: 'POST',
    data,
    withCredentials: true,
  });
};

export const refreshToken = () => {
  return axiosWrapper.request({
    url: `/auth/refresh-token`,
    method: 'POST',
    withCredentials: true,
  });
};

export const logout = () => {
  return axiosWrapper.request({
    url: `/auth/logout`,
    method: 'POST',
    withCredentials: true,
  });
};
