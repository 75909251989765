import constants from 'redux/constants';

export const setLoggedInUser = (user) => {
  return {
    type: constants.auth.SET_USER,
    payload: { ...user },
  };
};

export const clearSession = () => {
  return {
    type: constants.auth.CLEAR_SESSION,
  };
};
