import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { clearSession } from 'redux/actions/authActions';
import { getIsLoggedIn, getUser } from 'redux/reducers/authReducer';
import { getRoute, login, ROUTES } from 'utils/routes';
import { logout } from 'services/AuthService';

function Navbar() {
  const isLoggedIn = useSelector(getIsLoggedIn);
  const user = useSelector(getUser);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (isLoggedIn) {
      initializeSidebar();
    }
  }, [isLoggedIn]);

  const logOut = async () => {
    try {
      await logout();
      dispatch(clearSession());
      history.push(login.path);
    } catch {}
  };

  const initializeSidebar = () => {
    window.App.initSidebars();
  };

  const getNavbarForAuthorizedUsers = () => {
    return (
      <div className="navbar navbar-expand-md navbar-light fixed-top">
        <div className="navbar-header navbar-dark d-none d-md-flex align-items-md-center">
          <div className="navbar-brand navbar-brand-md">
            <a href="#" className="d-inline-block">
              <img src="assets/global/images/logo_light.png" alt="" />
            </a>
          </div>

          <div className="navbar-brand navbar-brand-xs">
            <a href="#" className="d-inline-block">
              <img src="assets/global/images/logo_icon_light.png" alt="" />
            </a>
          </div>
        </div>
        <div className="d-flex flex-1 d-md-none">
          <div className="navbar-brand mr-auto">
            <a href="#" className="d-inline-block">
              <img src="assets/global/images/logo_dark.png" alt="" />
            </a>
          </div>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbar-mobile"
          >
            <i className="icon-tree5"></i>
          </button>
          <button className="navbar-toggler sidebar-mobile-main-toggle" type="button">
            <i className="icon-paragraph-justify3"></i>
          </button>
        </div>
        <div className="collapse navbar-collapse" id="navbar-mobile">
          <ul className="navbar-nav">
            <li className="nav-item">
              <a
                href="#"
                className="navbar-nav-link sidebar-control sidebar-main-toggle d-none d-md-block"
              >
                <i className="icon-paragraph-justify3"></i>
              </a>
            </li>
          </ul>
          <ul className="navbar-nav ml-auto">
            <li className="nav-item dropdown dropdown-user">
              <a
                href="#"
                className="navbar-nav-link d-flex align-items-center dropdown-toggle"
                data-toggle="dropdown"
                aria-expanded="false"
              >
                <span>{user.firstName}</span>
              </a>
              <div className="dropdown-menu dropdown-menu-right">
                <Link to={getRoute(ROUTES.USER_PROFILE)} className="dropdown-item">
                  <i className="icon-cog3"></i> My Profile
                </Link>

                <a href="#" className="dropdown-item" onClick={() => logOut()}>
                  <i className="icon-switch2"></i> Logout
                </a>
              </div>
            </li>
          </ul>
        </div>
      </div>
    );
  };

  const getNavbarForNonAuthorizedUsers = () => {
    return (
      <div className="navbar navbar-expand-md navbar-dark fixed-top login-navbar">
        <div className="navbar-brand">
          <a href="#" className="d-inline-block">
            <img src="assets/global/images/logo_light.png" alt="" />
          </a>
        </div>
      </div>
    );
  };

  return isLoggedIn ? getNavbarForAuthorizedUsers() : getNavbarForNonAuthorizedUsers();
}

export default Navbar;
