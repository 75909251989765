import React from 'react';
import PropTypes from 'prop-types';
import './Loading.scss';

function Loading(props) {
  return (
    <div className="loading" style={{ height: props.height, ...props.style }}>
      <div className="loader loader--animating loader--gray loader--large">
        <div className="loader__blade" />
        <div className="loader__blade" />
        <div className="loader__blade" />
        <div className="loader__blade" />
        <div className="loader__blade" />
        <div className="loader__blade" />
        <div className="loader__blade" />
        <div className="loader__blade" />
        <div className="loader__blade" />
        <div className="loader__blade" />
        <div className="loader__blade" />
        <div className="loader__blade" />
      </div>
    </div>
  );
}

Loading.propTypes = {
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Loading.defaultProps = {
  height: 120,
};

export default Loading;
