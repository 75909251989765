export const BUTTON_TYPES = {
  PRIMARY: 0,
  SECONDARY: 1,
};

export const StatusCodes = {
  OK: 200,
  CREATED: 201,
  NO_CONTENT: 204,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  BAD_REQUEST: 400,
  NOT_FOUND: 404,
  CONFLICT: 409,
  INVALID_CURRENT_PASSWORD: 1001,
};

export const SORT_DIRECTION = {
  ASCENDING: 0,
  DESCENDING: 1,
};

export const ALERT_DURATION = {
  NORMAL: 3000,
};

export const ALERT_VARIANT = {
  SUCCESS: 'success',
  DANGER: 'danger',
  WARNING: 'warning',
  ERROR: 'error',
};
