import constants from 'redux/constants';
import _ from 'lodash';
import { ALERT_DURATION } from 'utils/constants';
import { getStore } from 'components/App';
import { clearNotification } from 'redux/actions/alertActions';

const INITIAL = {
  notifications: [],
};

const alertReducers = (state = INITIAL, action) => {
  const { type, payload } = action;
  switch (type) {
    case constants.alert.ADD_NOTIFICATION:
      const notificationId = _.random(99999);

      setInterval(() => {
        getStore().dispatch(clearNotification(notificationId));
      }, payload.duration ?? ALERT_DURATION.NORMAL);

      return {
        ...state,
        notifications: [
          ...state.notifications,
          { ...payload, duration: payload.duration ?? ALERT_DURATION.NORMAL, id: notificationId },
        ],
      };
    case constants.alert.CLEAR_NOTIFICATION:
      return {
        ...state,
        notifications: [...state.notifications.filter((x) => x.id !== payload.id)],
      };
    default:
      return state;
  }
};

export default alertReducers;

export const getNotifications = (state) => state.alert.notifications;
