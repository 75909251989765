const constants = {
  auth: {
    SET_USER: 'auth/setUser',
    CLEAR_SESSION: 'auth/clearSession',
  },
  alert: {
    ADD_NOTIFICATION: 'alert/addNotification',
    CLEAR_NOTIFICATION: 'alert/clearNotification',
  },
};

export default constants;
