import React, { Suspense, useEffect, useState } from 'react';
import { Route, Switch } from 'react-router';
import { Navbar, Sidebar, Footer, PageContent, ContentWrapper, CenterContent } from 'components/UI';
import { routes, notFound, login } from 'utils/routes';
import Loading from 'components/Shared/Loading/Loading';
import { useDispatch, useSelector } from 'react-redux';
import { getIsLoggedIn } from 'redux/reducers/authReducer';
import { refreshToken } from 'services/AuthService';
import { StatusCodes } from 'utils/constants';
import { setLoggedInUser } from 'redux/actions/authActions';
import Alerts from 'components/Shared/Alert/Alerts';

function Content() {
  const dispatch = useDispatch();

  const [isRefreshingToken, setIsRefreshingToken] = useState(true);

  useEffect(() => {
    refreshSession();
  }, []);

  const LoadingCentered = (
    <CenterContent>
      <Loading height={550} />
    </CenterContent>
  );

  const isLoggedIn = useSelector(getIsLoggedIn);

  const refreshSession = async () => {
    try {
      const response = await refreshToken();
      if (response.status === StatusCodes.OK) {
        dispatch(setLoggedInUser({ ...response.data }));
      }
    } catch {
    } finally {
      setIsRefreshingToken(false);
    }
  };

  const getAuthRoutes = () => {
    if (!isLoggedIn) {
      var routesToRender = routes.map((x) => {
        if (!x.requireAuth || (x.requireAuth && isLoggedIn))
          return <Route path={x.path} component={x.component} key={x.id}></Route>;
        return null;
      });
      routesToRender.push(<Route component={login.component} key={login.id}></Route>);
      return routesToRender;
    } else {
      var routesToRender = routes.map((x) => {
        if (!x.requireAuth || (x.requireAuth && isLoggedIn))
          return <Route path={x.path} component={x.component} key={x.id}></Route>;
        return null;
      });
      routesToRender.push(<Route component={notFound.component} key={notFound.id}></Route>);
      return routesToRender;
    }
  };

  var renderApplication = () => {
    return (
      <React.Fragment>
        <Navbar />
        <Alerts />
        <PageContent>
          <Sidebar />
          <Suspense fallback={LoadingCentered}>
            <ContentWrapper>
              <Switch>{getAuthRoutes()}</Switch>
              <Footer></Footer>
            </ContentWrapper>
          </Suspense>
        </PageContent>
      </React.Fragment>
    );
  };

  var renderLoading = () => {
    return <React.Fragment>{LoadingCentered}</React.Fragment>;
  };

  return isRefreshingToken ? renderLoading() : renderApplication();
}

export default Content;
