import React from 'react';

export const ROUTES = {
  LOGIN: 1,
  COMPANIES: 2,
  SITES: 3,
  CONTAINERS: 4,
  NODES: 5,
  PRODUCTS: 6,
  WEBSITE_USERS: 7,
  SITE_USERS: 8,
  USER_PROFILE: 9,
  NOT_FOUND: 10,
  CATEGORIES: 11,
  SUBCATEGORIES: 12,
  DEPARTMENTS: 13,
};

export const login = {
  component: React.lazy(() => import('components/Auth/Login')),
  path: '/login',
  id: ROUTES.LOGIN,
  requireAuth: false,
};
export const companies = {
  component: React.lazy(() => import('components/Forms/Companies/Companies')),
  path: '/companies',
  id: ROUTES.COMPANIES,
  requireAuth: true,
};
export const sites = {
  component: React.lazy(() => import('components/Forms/Sites/Sites')),
  path: '/sites',
  id: ROUTES.SITES,
  requireAuth: true,
};
export const containers = {
  component: React.lazy(() => import('components/Forms/Containers/Containers')),
  path: '/containers',
  id: ROUTES.CONTAINERS,
  requireAuth: true,
};
export const nodes = {
  component: React.lazy(() => import('components/Forms/Nodes/Nodes')),
  path: '/nodes',
  id: ROUTES.NODES,
  requireAuth: true,
};
export const products = {
  component: React.lazy(() => import('components/Forms/Products/Products')),
  path: '/products',
  id: ROUTES.PRODUCTS,
  requireAuth: true,
};
export const websiteUsers = {
  component: React.lazy(() => import('components/Forms/WebsiteUsers/WebsiteUsers')),
  path: '/website-users',
  id: ROUTES.WEBSITE_USERS,
  requireAuth: true,
};
export const siteUsers = {
  component: React.lazy(() => import('components/Forms/SiteUsers/SiteUsers')),
  path: '/site-users',
  id: ROUTES.SITE_USERS,
  requireAuth: true,
};
export const categories = {
  component: React.lazy(() => import('components/Forms/Categories/Categories')),
  path: '/categories',
  id: ROUTES.CATEGORIES,
  requireAuth: true,
};
export const subcategories = {
  component: React.lazy(() => import('components/Forms/Subcategories/Subcategories')),
  path: '/subcategories',
  id: ROUTES.SUBCATEGORIES,
  requireAuth: true,
};
export const departments = {
  component: React.lazy(() => import('components/Forms/Departments/Departments')),
  path: '/departments',
  id: ROUTES.DEPARTMENTS,
  requireAuth: true,
};
export const userProfile = {
  component: React.lazy(() => import('components/Forms/UserProfile')),
  path: '/my-profile',
  id: ROUTES.USER_PROFILE,
  requireAuth: true,
};

export const notFound = {
  component: React.lazy(() => import('components/UI/NotFound')),
  id: ROUTES.NOT_FOUND,
  requireAuth: false,
};

export const routes = [
  companies,
  sites,
  containers,
  nodes,
  products,
  websiteUsers,
  siteUsers,
  userProfile,
  categories,
  subcategories,
  departments,
];

export const getRoute = (id) => {
  return routes.find((x) => x.id === id).path;
};

export const sidebarItems = [
  {
    path: companies.path,
    icon: 'icon-home4',
    title: 'Clients',
  },
  {
    path: sites.path,
    icon: 'icon-location3',
    title: 'Cabinets',
  },
  {
    path: containers.path,
    icon: 'icon-server',
    title: 'Containers',
  },
  {
    path: nodes.path,
    icon: 'icon-balance',
    title: 'Nodes',
  },
  {
    path: products.path,
    icon: 'icon-price-tags',
    title: 'Products',
  },
  {
    path: departments.path,
    icon: 'icon-home7',
    title: 'Departments',
  },
  {
    path: categories.path,
    icon: 'icon-files-empty',
    title: 'Categories',
  },
  {
    path: subcategories.path,
    icon: 'icon-files-empty2',
    title: 'Subcategories',
  },
  {
    path: [
      { path: websiteUsers.path, title: 'Website Users' },
      { path: siteUsers.path, title: 'Cabinet Users' },
    ],
    icon: 'icon-users',
    title: 'Users',
  },
];
