import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createStore, combineReducers } from 'redux';
import Content from 'components/UI/Content';
import authReducer from 'redux/reducers/authReducer';
import React from 'react';
import alertReducers from 'redux/reducers/alertReducer';
import './App.css';

const rootReducer = combineReducers({
  auth: authReducer,
  alert: alertReducers,
});

const store = createStore(rootReducer);

export const getStore = () => {
  return store;
};

function App() {
  return (
    <React.Fragment>
      <Provider store={getStore()}>
        <BrowserRouter>
          <Content></Content>
        </BrowserRouter>
      </Provider>
    </React.Fragment>
  );
}

export default App;
