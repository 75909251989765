export const focusOnElement = (selector) => {
  const errorElement = document.querySelector(`[name="${selector}"]`);
  if (errorElement && errorElement.type === 'hidden') {
    const errorElementInputSearchable = document.querySelector(`input[data-name="${selector}"]`);
    if (errorElementInputSearchable) {
      errorElementInputSearchable.focus();
      return;
    }
    const errorElementInput = errorElement.parentElement.querySelector(
      `input[id*="react-select-"]`
    );
    if (errorElementInput) {
      errorElementInput.focus();
      return;
    }
  }
  if (errorElement) errorElement.focus();
};

export const getDataFromEvent = (event) => {
  if (event.target !== undefined && event.target.files !== undefined) {
    const multipleFiles = event.target.files.length > 1;

    if (multipleFiles) {
      return event.target.files;
    }

    return event.target.files[0];
  } else if (event.dataTransfer !== undefined && event.dataTransfer.files !== undefined) {
    const multipleFiles = event.dataTransfer.files.length > 1;

    if (multipleFiles) {
      return event.dataTransfer.files;
    }

    return event.dataTransfer.files[0];
  }
};

export function classNames() {
  let hasOwn = {}.hasOwnProperty;
  let classes = [];

  for (let i = 0; i < arguments.length; i++) {
    let arg = arguments[i];
    if (!arg) continue;

    let argType = typeof arg;

    if (argType === 'string' || argType === 'number') {
      classes.push(arg);
    } else if (Array.isArray(arg) && arg.length) {
      let inner = classNames.apply(null, arg);
      if (inner) {
        classes.push(inner);
      }
    } else if (argType === 'object') {
      for (let key in arg) {
        if (hasOwn.call(arg, key) && arg[key]) {
          classes.push(key);
        }
      }
    }
  }

  return classes.length ? classes.join(' ') : undefined;
}
