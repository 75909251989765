import { useDispatch, useSelector } from 'react-redux';
import { getNotifications } from 'redux/reducers/alertReducer';
import BootstrapAlert from 'react-bootstrap/Alert';
import { classNames } from 'utils/helpers';
import { clearNotification } from 'redux/actions/alertActions';
import { ALERT_VARIANT } from 'utils/constants';

function Alerts() {
  const notifications = useSelector(getNotifications);
  const dispatch = useDispatch();

  return (
    <div className="alerts-container-global">
      {notifications.map((a) => {
        const dismissible = a.dismissible != null ? a.dismissible : true;
        const variant = a.variant != null ? a.variant : ALERT_VARIANT.SUCCESS;

        return (
          <>
            <BootstrapAlert
              className={classNames(variant, a.className)}
              variant={variant}
              onClose={() => dispatch(clearNotification(a.id))}
              dismissible={dismissible}
              style={a.style}
              key={a.id}
              id={a.id}
            >
              <BootstrapAlert.Heading>{a.title}</BootstrapAlert.Heading>
              {a.message}
            </BootstrapAlert>
          </>
        );
      })}
    </div>
  );
}

export default Alerts;
