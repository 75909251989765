import constants from 'redux/constants';

const INITIAL = {
  user: { userId: '', token: '', firstName: '', lastName: '', permissions: [], tokenExpireDate: 0 },
  loggedIn: false,
};

const authReducer = (state = INITIAL, action) => {
  const { type, payload } = action;
  switch (type) {
    case constants.auth.SET_USER:
      return { ...state, user: { ...payload }, loggedIn: true };
    case constants.auth.CLEAR_SESSION:
      return { ...INITIAL };
    default:
      return state;
  }
};

export default authReducer;

export const getUser = (state) => state.auth.user;
export const getIsLoggedIn = (state) => state.auth.loggedIn;
