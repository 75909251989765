import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import { getIsLoggedIn, getUser } from 'redux/reducers/authReducer';
import { ROUTES, sidebarItems, getRoute } from 'utils/routes';

function Sidebar() {
  const isLoggedIn = useSelector(getIsLoggedIn);
  const user = useSelector(getUser);

  useEffect(() => {
    if (isLoggedIn) {
      initializeNavigation();
    }
  }, [isLoggedIn]);

  const initializeNavigation = () => {
    window.App.initNavigations();
  };

  const renderSideBarItems = () => {
    return sidebarItems.map((x) => {
      if (!Array.isArray(x.path)) {
        return (
          <li className="nav-item" key={x.title}>
            <NavLink to={x.path} className="nav-link">
              <i className={x.icon}></i>
              <span>{x.title}</span>
            </NavLink>
          </li>
        );
      } else {
        return (
          <li className="nav-item nav-item-submenu" key={x.title}>
            <Link to="#" className="nav-link">
              <i className={x.icon}></i>
              <span>{x.title}</span>
            </Link>
            <ul className="nav nav-group-sub" data-submenu-title={x.title}>
              {x.path.map((y) => (
                <li className="nav-item" key={y.path}>
                  <NavLink to={y.path} className="nav-link">
                    {y.title}
                  </NavLink>
                </li>
              ))}
            </ul>
          </li>
        );
      }
    });
  };

  const getSidebar = () => {
    return (
      <div className="sidebar sidebar-dark sidebar-main sidebar-expand-md">
        <div className="sidebar-mobile-toggler text-center">
          <a href="#" className="sidebar-mobile-main-toggle">
            <i className="icon-arrow-left8"></i>
          </a>
          <a href="#" className="sidebar-mobile-expand">
            <i className="icon-screen-full"></i>
            <i className="icon-screen-normal"></i>
          </a>
        </div>
        <div className="sidebar-content">
          <div className="sidebar-user">
            <div className="card-body">
              <div className="media">
                <div className="mr-3">
                  <a href="#">
                    <img
                      src="assets/global/images/image.png"
                      width="38"
                      height="38"
                      className="rounded-circle"
                      alt=""
                    />
                  </a>
                </div>
                <div className="media-body">
                  <div className="media-title font-weight-semibold">
                    {user.firstName}&nbsp;{user.lastName}
                  </div>
                  <div className="font-size-xs opacity-50">{/*TODO:ROLE*/}</div>
                </div>
                <div className="ml-3 align-self-center">
                  <Link to={getRoute(ROUTES.USER_PROFILE)} className="text-white">
                    <i className="icon-cog3"></i>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="card card-sidebar-mobile">
            <ul className="nav nav-sidebar" data-nav-type="accordion">
              <li className="nav-item-header">
                <div className="text-uppercase font-size-xs line-height-xs">Menu</div>
                <i className="icon-menu" title="Main"></i>
              </li>
              {renderSideBarItems()}
            </ul>
          </div>
        </div>
      </div>
    );
  };

  return isLoggedIn ? getSidebar() : null;
}

export default Sidebar;
